import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import ServiceHero from "../../components/service-hero";
import PhotoSide from "../../components/photo-side";
import Testimonials from "../../components/testimonials";
import Certifications from "../../components/certifications";
import DotsCta from "../../components/dots-cta";
import BgImageCta from "../../components/bg-image-cta";

const Commercial = () => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImg: file(relativePath: { eq: "fuse-bg.jpg" }) {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: FULL_WIDTH
							placeholder: BLURRED
						)
					}
				}
				logoImg: file(relativePath: { eq: "logo.png" }) {
					publicURL
				}
				wireBgImg: file(relativePath: { eq: "wire-bg.png" }) {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: FULL_WIDTH
							placeholder: BLURRED
						)
					}
				}
				wireImg: file(relativePath: { eq: "wire.png" }) {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
				reviews {
					reviewCount
				}
				site {
					siteMetadata {
						siteUrl
						company
					}
				}
			}
		`
	);
	const reviewCount = data.reviews.reviewCount;
	const schemaImg = data.heroImg.publicURL;
	const logoImg = data.logoImg.publicURL;
	const heroImg = data.heroImg.childImageSharp.gatsbyImageData;
	const wireImg = data.wireImg.childImageSharp.gatsbyImageData;
	const wireBgImg = data.wireBgImg.childImageSharp.gatsbyImageData;
	const siteUrl = data.site.siteMetadata.siteUrl;
	const companyName = data.site.siteMetadata.company;
	const pageUrl = "/services/commercial";
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Prime Sparks",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: " Services",
				item:{ 
					url:`${siteUrl}/services`,
					id:`${siteUrl}/services`,
			},
		},
			{
				"@type": "ListItem",
				position: 3,
				name: "Commercial Electrical Services",
				item:{ 
					url:`${siteUrl}/services/commercial`,
					id:`${siteUrl}/services/commercial`,
			},
		},
		],
	};
	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `${siteUrl}${pageUrl}#commercial-electrical-services`,
		image: `${siteUrl}${schemaImg}`,
		mpn: `${siteUrl}`,
		sku: `${siteUrl}${pageUrl}`,
		description:
			"Prime Sparks provides a full suite of electrical services to ensure local businesses keep running and are compliant with the regulations.",
		logo: `${siteUrl}${logoImg}`,
		name: `${companyName}`,
		url: `${siteUrl}`,
		brand: {
			"@type": "Organization",
			logo: `${siteUrl}${logoImg}`,
			image: `${siteUrl}${schemaImg}`,
			name: `${companyName}`,
			url: `${siteUrl}`,
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: `${siteUrl}/testimonials`,
			datePublished: "2021-03-09T17:58:29+00:00",
			reviewBody:
				"We use Roger @ Prime Sparks for many of our managed properties, he is always prompt, efficient and never lets us down!",
			author: {
				"@type": "Person",
				name: "Mason Byrant",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: `${companyName}`,
				sameAs: `${siteUrl}`,
			},
		},
	};
	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>
			<GatsbySeo
				title="Commercial Electrical Services in Eastbourne | Prime Sparks"
				description="Prime Sparks provides a full suite of electrical services to ensure local businesses keep running and are compliant with the regulations."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Commercial Electrical Services | Prime Sparks",
					description:
						"Prime Sparks provides a full suite of electrical services to ensure local businesses keep running and are compliant with the regulations.",
					images: [
						{
							url: `${schemaImg}`,
							width: 1920,
							height: 1080,
							alt: "Commercial Electrical Services in Eastbourne",
						},
					],
				}}
			/>
			<ServiceHero
				heading="Commercial Electrical Services"
				text={
					<span>
						Prime Sparks provides a full suite of electrical services to ensure
						local <br /> businesses keep running and are compliant with the
						regulations.
					</span>
				}
				isDarken={true}
				image={heroImg}
			/>
			<DotsCta
				sHeading="Commercial Electrical Services in Eastbourne"
				heading={
					<span>
						{" "}
						Do your
						<span className="text-primary"> business premises </span>
						need electrical work carried out?
					</span>
				}
				text=" Prime Sparks offers a wide variety of electrical services to local businesses in Eastbourne and East Sussex. Prime Sparks is a fully EICR and PAT certified local company that can help with all kinds of electrical installation or maintenance needs that your business premises may have. "
				btnText="Get in Touch"
				btnUrl="/contact-us"
			/>
			<Certifications />
			<PhotoSide
				img={wireImg}
				height="35rem"
				heading={
					<span>
						Would you like the{" "}
						<span className="text-primary">peace of mind</span> of knowing that
						you will receive a prompt, professional service?
					</span>
				}
				cta="GET IN TOUCH"
				ctaUrl="/contact-us"
				text={
					<span>
						<p>
							Here at Prime Sparks we know that the safety of your staff and
							customers is of paramount importance, which is why it is so
							important to contract an electrician to carry out electrical
							services on your premises who is not only fully qualified, but
							works to the highest industry standards.
						</p>
						<p>
							{" "}
							Customer satisfaction is very important to us, which is why we aim
							to deliver the best service possible at very competitive rates and
							will keep you informed of when the work will be carried out so
							that you can ensure business continuity.
						</p>
					</span>
				}
			/>
			<Testimonials />
			<BgImageCta
				focus="center"
				list={true}
				vHeight="40rem"
				img={wireBgImg}
				heading={
					<span>
						Are you{" "}
						<span className="text-primary">looking for an electrician</span> to
						carry out any of the following commercial electrical work?
					</span>
				}
				text={
					<span>
						<ul className="dashed pl-0">
							<li>- Fault finding </li>
							<li>- CCTV </li>
							<li>- Emergency lighting</li>
							<li>- Security lighting</li>
							<li>- Office lighting</li>
							<li>- Telephone installation</li>
							<li>- Data networking</li>
							<li>- Electrical appliance repairs</li>
							<li>- Storage heater repairs/replacements</li>
							<li>- Fire alarm testing/installation</li>
							<li>- Energy-efficient heaters and dryers</li>
							<li>- Testing and inspections</li>
						</ul>
					</span>
				}
				cta="CONTACT US"
				ctaUrl="/contact-us"
			/>
			<div style={{ height: "5rem" }}></div>
		</Layout>
	);
};
export default Commercial;
